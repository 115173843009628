/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';

import heroPortfolio from 'assets/images/portfolio.jpg';

export default function HeroPortfolio() {
  return (
    <section className="hero sm:items-center lg:items-start sm:flex-row">
      <Fade bottom>
        <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
          <h1 className="text-6xl text-theme-blue font-bold leading-tight mb-5">
            Portfolio
          </h1>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
            Dedicated to fostering innovation across diverse disciplines, we blend interdisciplinary activities to create impactful resources for all stakeholders. Our focus lies in developing cutting-edge legal tech products, supporting student entrepreneurs, and producing thought-provoking reports to advance legal innovation. With a commitment to accessibility, we ensure our efforts benefit a wide range of individuals and organizations.
            <br />
            <br />
            Join us as we push boundaries and drive meaningful change in the legal landscape.
          </p>
        </div>
        <div className="w-full sm:w-1/2 sm:pr-12">
          <img src={heroPortfolio} alt="Hero" />
        </div>
      </Fade>
    </section>
  );
}
