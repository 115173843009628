/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';

export default function AllTeam({ data }) {
  return (
    <section className="container mx-0 sm:mx-auto bg-gray-50">
      <div className="container mx-auto pt-20 pb-28">
        <Fade bottom>
          <h1 className="text-5xl text-theme-blue text-center font-bold">
            Advisors
          </h1>
        </Fade>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-y-8 gap-x-32 sm:gap-14 lg:gap-10 mx-16 justify-items-center">
          {data.map((item, index) => (
            <Fade bottom delay={200 * index}>
              <div>
                <div className="flex flex-col w-45 h-60 sm:w-56 sm:h-72 discussform rounded-xl shadow-xl border border-light-theme-purple justify-center transform transition duration-500 hover:scale-105">
                  {/* hi flex-col w-44 h-60 sm:w-56 sm:h-72 rounded-xl shadow-xl border border- */}
                  <div className="flex justify-center xl:mb-5">
                    <input
                      type="image"
                      src={item.imageUrl}
                      alt="Advisor"
                      className="flex custom-size rounded-full"
                      onClick={() => window.open(item.link, '_blank')}
                    />
                  </div>
                  <h2 className="text-theme-blue text-center text-xl">
                    {item.name}
                  </h2>
                  <p className="font-light text-gray-400 text-center mb-3">
                    {item.position}
                    <br />
                    {item.location}
                  </p>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
}
