/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Button from 'elements/Button';
import main from '../assets/images/main-logo.jpeg';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <div>
        <img src={main} alt="" width={300} />
      </div>
      {/* <p className="text-theme-blue text-4xl">
        Racx
        <span className="text-theme-purple">studio</span>
      </p> */}
    </Button>
  );
}
